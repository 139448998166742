<div class="c-modal" role="dialog" style="display: block;" *ngIf="state !== 0">
    <div class="c-modal__content">
        <div class="c-modal-top-nav">
            <button class="c-ic-button" role="button" aria-label="back" [tabindex]="state === 1 || state === 4 ? '-1' : ''" (click)="back()" [ngClass]="{'u-is-hidden': state === 1 || state === 4}">
                <svg class="c-ic c-ic--sm">
                    <use xlink:href="#ic-back-modal" href="#ic-back-modal"></use>
                </svg>
            </button>
            <div class="c-modal-top-nav__logo">
                <svg class="c-ic-purple c-modal-top-nav__logo-img">
                    <use xlink:href="#ic-logo-2" href="#ic-logo-2"></use>
                </svg>
            </div>
            <button class="c-ic-button c-modal-top-nav__close" role="button" aria-label="close modal" (click)="close()" id="dismiss-losses-modal-button">
                <svg class="c-ic c-ic--sm">
                    <use xlink:href="#ic-close" href="#ic-close"></use>
                </svg>
            </button>
        </div>

        <div class="c-modal__content__body">
            <ng-container *ngIf="state === 1">
                <p class="c-text-width c-text-dark" *ngIf="isSettled">Your case has now been settled and you can no longer add losses. If you have any questions please <a href="javascript:undefined" (click)="close()" routerLink="./contact">contact us</a>.</p>
                <ng-container *ngIf="!isSettled">
                    <p class="c-text-width c-text-dark">Choose your loss type and sub category which best describes the loss you are claiming for. To learn more about losses visit our <a (click)="closeAndGoToFaqs()" disableTabOnModal class="c-text-link underline">FAQs centre</a>.</p>
                </ng-container>
                <div id="add-loss-type-selection-panel" *ngIf="!isSettled">
                    <h2 class="c-heading c-heading-purple">Choose your loss type</h2>
                    <div class="c-button-circle-spacing--modal">
                        <ul class="c-text-list-style-none u-flex-row u-flex-wrap u-margin-none">
                            <li class="c-button-circle-list" *ngFor="let lossType of lossTypes; let i = index;">
                                <div class="u-flex">
                                    <div class="c-button-circle-wrapper">
                                        <button [attr.aria-label]="'Choose ' + lossType.name + ' loss category'" [id]="'add-loss-type-button-' + i" (click)="setLossType(lossType)" class="c-button-circle" [ngClass]="{'c-button-circle--active' : lossType === selectedLossType, 'is-secondary' : lossType !== selectedLossType}">
                                            <span class="u-hide-content">{{lossType.name}}</span>
                                            <span class="c-ic c-ic--sm c-ic-vertical c-ic-dark"><i class="c-ic-font-awesome fas fa-{{lossType.icon}}" [ngClass]="{'c-ic-font-awesome--white' : lossType === selectedLossType, 'c-ic-font-awesome' : lossType !== selectedLossType}"></i></span>
                                        </button>
                                        <div class="c-button-circle__text c-button-circle__text--black">{{lossType.name}}</div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <ng-container *ngIf="availableSubLossTypes && availableSubLossTypes.length > 0">
                        <h2 class="c-heading c-heading-purple">Choose your {{selectedLossType.name}} loss</h2>
                        <div class="c-button-circle-spacing--modal">
                            <ul class="c-text-list-style-none u-flex-row u-flex-wrap u-margin-none">
                                <li class="c-button-circle-list" *ngFor="let lossType of availableSubLossTypes; let i = index;">
                                    <div class="u-flex">
                                        <div class="c-button-circle-wrapper">
                                            <button [attr.aria-label]="'Choose ' + lossType.name + ' loss type'" [id]="'add-sub-loss-type-button-' + i" (click)="setSubLossType(lossType)" class="c-button-circle" [ngClass]="{'c-button-circle--active' : lossType === selectedSubLossType, 'is-secondary' : lossType !== selectedSubLossType}">
                                                <span class="u-hide-content">{{lossType.name}}</span>
                                                <span class="c-ic c-ic--sm c-ic-vertical c-ic-dark"><i class="c-ic-font-awesome fas fa-{{lossType.icon}}" [ngClass]="{'c-ic-font-awesome--white' : lossType === selectedSubLossType, 'c-ic-font-awesome' : lossType !== selectedSubLossType}"></i></span>
                                            </button>
                                            <div class="c-button-circle__text c-button-circle__text--black">{{lossType.name}}</div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </ng-container>
                    <div class="u-flex-center  u-mtop-ism">
                        <minster-button id="select-type-submit-button" *ngIf="selectedSubLossType" (click)="state = 2" buttonClass="c-button" isSuccess="true" buttonType="button" [disabled]="submitting" text="Next" [isLoading]="isLoading ? true : false" ariaLabel="Add loss types" [isLong]="true"></minster-button>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="state === 2">
                <div id="add-loss-upload-evidence-panel">
                    <h2 class="c-heading c-heading-purple">Upload evidence</h2>
                    <p class="c-text-width c-text-dark">
                        We can accept a range of files as evidence including images, PDFs, Word and Excel.
                        <ng-container *ngIf="canUploadInfinite">You can provide as many files as you need to evidence a loss.
                        </ng-container><ng-container *ngIf="!canUploadInfinite">You can provide up to a maximum of {{maxFileCount}} files as evidence of recorded loss.</ng-container>
                        However, each file must not exceed 10mb and the total file upload must not exceed {{uploadLimit}}mb. When uploading large files we recommend using a Wi-Fi connection.
                    </p>
                    <h2 class="c-heading c-heading-purple" *ngIf="docsToUpload && docsToUpload.length > 0">Selected evidence:</h2>
                    <div class="c-uploaded-doc-wrapper u-mtop-ism" *ngIf="docsToUpload && docsToUpload.length > 0">
                        <ul class="c-uploaded-doc-wrapper" aria-label="Uploaded loss evidence">
                            <li class="c-uploaded-doc-item" *ngFor="let doc of docsToUpload; let i = index;">
                                <div class="c-uploaded-doc">
                                    <div>
                                        <svg class=" c-ic c-ic--md c-ic-purple"> <use xlink:href="#ic-image" href="#ic-image"></use></svg>
                                    </div>
                                    <span class="c-uploaded-doc__text" [id]="'add-loss-filename-' + i">{{doc.newFileName}}</span>
                                    <button class="c-ic-button" *ngIf="canRemoveDocuments" [id]="'add-loss-removefile-' + i" (click)="removeFile(doc)" [attr.aria-label]="'Remove file: ' + doc.newFileName">
                                        <svg class=" c-ic c-ic--sm c-ic-blue"> <use xlink:href="#ic-delete" href="#ic-delete"></use></svg>
                                    </button>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <p *ngIf="isLoading">
                        <ngx-loading-bar [fixed]="false" [height]="10"></ngx-loading-bar>
                    </p>
                    <div class="message is-danger" *ngIf="fileErrors && fileErrors.length > 0">
                        <ul>
                            <li *ngFor="let fileError of fileErrors; let i = index;" [id]="'add-loss-fileerror-' + i">{{fileError}}</li>
                        </ul>
                    </div>
                </div>

                <form action="" (submit)="submitDocumentSelection(fileInput)">
                    <div class="u-flex-center  u-mtop-md">
                        <input type="file" #fileInput (change)="evidenceFilesChanged($event)" placeholder="Upload file" multiple accept=".bmp,.doc,.docx,.gif,.jpg,.jpeg,.pdf,.png,.tif,.tiff,.txt,.xls,.xlsx" style="display: none">
                        <minster-button [isFull]="true" id="fileUploadSubmit" [disabled]="!canRemoveDocuments" buttonType="submit" aria-label="Add files" text="Add file(s)" buttonClass="c-button" ariaLabel="Add files"></minster-button>
                    </div>
                </form>
                <form [formGroup]="nameForm" action="" (submit)="uploadSubmit(nameForm)">
                    <div class="c-text-input u-mtop-ism" *ngIf="docsToUpload.length > 0">
                        <label for="renameFiles" class="u-text-bold">Rename files (optional)</label>
                        <input class="c-text-input-style" type="text" [formControl]="nameForm.controls['fileName']" id="renameFiles" name="Rename File(s)" [disabled]="this.documentGroupId > 0">
                        <ng-container *ngIf="nameFormSubmitted">
                            <minster-validation-message *ngIf="nameForm.controls.fileName.hasError('maxlength')" message="Your new file name cannot exceed 50 characters."></minster-validation-message>
                            <minster-validation-message *ngIf="nameForm.controls.fileName.hasError('pattern')" message="Your new file name contains invalid characters."></minster-validation-message>
                        </ng-container>
                    </div>
                    <div class="u-flex-center">
                        <minster-button buttonType="submit" id="upload-evidence-add-files-next-button" [isFull]="true" [disabled]="uploadNextButtonDisabled" [isLoading]="isLoading" text="Next" buttonClass="c-button" submittingText="Submitting..." ariaLabel="Next step"></minster-button>
                    </div>
                </form>
            </ng-container>
            <ng-container *ngIf="state === 3">
                <div id="add-loss-describe-panel">
                    <h2 class="c-heading c-heading-purple">Loss details</h2>
                    <p class="c-text-width c-text-dark">Please provide more details about your loss.</p>
                    <form [formGroup]="form" action="" (submit)="submit(form)">
                        <div class="c-text-input-styled u-mtop-ism">
                            <label for="" class="u-text-bold" required="">Loss type*</label>
                            <svg class=" c-ic c-ic--sm c-text-input-styled__icon c-ic-dark"> <use xlink:href="#ic-lock" href="#ic-lock"></use></svg>
                            <div class="c-text-input-styled__icon-bg">
                                <input id="add-loss-selected-loss-type" type="text" required="" class="c-text-input-styled__box" readonly [value]="selectedLossType.name + ' - ' + selectedSubLossType.name">
                            </div>
                        </div>

                        <div class="c-text-input u-mtop-ism">
                            <label class="u-text-bold" for="loss-incurred-date">Date (DD/MM/YYYY)*</label>
                            <input class="c-text-input-style" type="date" [formControl]="form.controls['date']" id="add-loss-loss-incurred-date" />
                        </div>
                        <ng-container *ngIf="submitted">
                            <minster-validation-message *ngIf="form.controls.date.hasError('required')" message="Please provide the date you incurred your loss."></minster-validation-message>
                        </ng-container>
                        <div class="c-text-input-styled u-mtop-ism">
                            <label class="u-text-bold" for="amount">Amount*</label>
                            <svg class=" c-ic c-ic--xs c-text-input-styled__icon c-text-input-styled__icon--money c-ic-dark"> <use xlink:href="#ic-money" href="#ic-money"></use></svg>
                            <div class="c-text-input-styled__icon-bg">
                                <input class="c-text-input-styled__box" type="number" [formControl]="form.controls['amount']" id="add-loss-loss-amount" />
                            </div>
                        </div>
                        <ng-container *ngIf="submitted">
                            <minster-validation-message *ngIf="form.controls.amount.hasError('required')" message="Please provide the amount of loss you incurred."></minster-validation-message>
                            <minster-validation-message *ngIf="form.controls.amount.hasError('min')" message="Please provide the amount of loss you incurred. It must be > £0.01"></minster-validation-message>
                        </ng-container>
                        <div class="c-text-input u-mtop-ism">
                            <label class="u-text-bold" for="notes">Notes</label>
                            <textarea class="c-text-area" [formControl]="form.controls['notes']" id="add-loss-loss-notes"></textarea>
                        </div>
                        <ng-container *ngIf="submitted">
                            <minster-validation-message *ngIf="form.controls.notes.hasError('maxlength')" message="Your notes cannot exceed 500 characters."></minster-validation-message>
                            <minster-validation-message *ngIf="form.controls.notes.hasError('pattern')" message="Your notes contains invalid characters."></minster-validation-message>
                        </ng-container>
                        <div class="u-flex-center  u-mtop-ism">
                            <minster-button buttonClass="c-button" buttonType="submit" [disabled]="isLoading" id="add-loss-submit-button" text="Add loss" submittingText="Submitting..." isPrimary="true" [isLoading]="isLoading ? true : false" ariaLabel="add-loss-submit-loss"></minster-button>
                            <minster-button buttonType="button" (click)="close()" [disabled]="isLoading" id="add-loss-cancel-button" text="Cancel" isInfo="true" buttonClass="c-button" ariaLabel="add-loss-cancel"></minster-button>
                        </div>

                    </form>
                    <div class="message is-danger" *ngIf="fileErrors && fileErrors.length > 0">
                        <ul>
                            <li *ngFor="let fileError of fileErrors">{{fileError}}</li>
                        </ul>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="state === 4">
                <div id="add-loss-complete-panel">
                    <h2 class="c-heading c-heading-purple">Upload successful</h2>
                    <p class="c-text-width c-text-dark">
                        Thank you, your loss has been added to your account.
                    </p>
                    <p class="c-text-width c-text-dark">
                        We will review this as part of your claim.
                    </p>
                </div>
                <div class="u-flex-center  u-mtop-ism">
                    <minster-button [isLong]="true" (click)="addAnotherLoss()" id="add-another-loss-button" text="Add another loss" buttonClass="c-button" ariaLabel="add-loss-add-new-loss"></minster-button>
                </div>
            </ng-container>
        </div>
    </div>
</div>

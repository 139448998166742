import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NotificationModel } from '../../models/notification.model';
import { CaseModel } from '../../models/case.model';
import { NotificationService } from '../../services/notification.service';
import { DocumentService } from '../../services/document.service';
import { DocumentModel } from '../../models/document.model';
import { AppUtilities } from '../../app.utility';
import { MinsterAlertComponent } from '../minster-alert/minster-alert.component';
import { AppInsightsService } from '../../services/appinsights.service';
import { NotificationDocumentEvent } from '../notification-row/notification-row.component';
import { MedicalReportService, MedicalReportRequest } from '../../services/medical-report.service';
import { KeyedCollection } from '../../data/keyedcollection';
import mime from 'mime-types';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {

    notifications: NotificationModel[];
    case: CaseModel;
    showAll = false;
    documents: DocumentModel[];
    notificationToConfirm: NotificationModel;
    subscriptions = [];
    @ViewChild('confirmDeleteWindow', { static: true }) alertWindow: MinsterAlertComponent;
    downloadingDocs = new KeyedCollection<boolean>();

    get notificationsToShow(): NotificationModel[] {
        if (this.showAll) {
            return this.notifications;
        }
        return this.notifications.filter(n => !n.isRead);
    }

    isDownloadingDocument(notification: NotificationModel): boolean {
        const doc = this.documentForNotification(notification);
        if (!doc) {
            return false;
        }
        return this.downloadingDocs.ContainsKey(doc.id);
    }

    documentForNotification(notification: NotificationModel): DocumentModel | null {
        if (!notification.notificationObject ||
            notification.notificationObject.trim().toLowerCase() !== 'document' ||
            !notification.notificationObjectId) {
            return null;
        }

        const matchingDocuments = this.documents.filter(d => d.id === notification.notificationObjectId);
        if (matchingDocuments.length === 0) {
            return null;
        }

        return matchingDocuments[0];
    }

    private replaceDocument(newDocument: DocumentModel) {
        const matches = this.documents.filter(m => m.id === newDocument.id);
        matches.forEach(m => {
            this.documents.splice(this.documents.indexOf(m), 1);
        });
        this.documents.push(newDocument);
    }

    notificationForDocument(document: DocumentModel): NotificationModel | null {
        const matches = this.notifications.filter(n => {
            const doc = this.documentForNotification(n);
            if (!doc) {
                return false;
            }

            return doc.id === document.id;
        });

        if (matches.length > 0) {
            return matches[0];
        }

        return null;
    }

    constructor(
        private route: ActivatedRoute,
        private notificationService: NotificationService,
        private documentService: DocumentService,
        private appInsights: AppInsightsService,
        private medicalReportService: MedicalReportService) { }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    ngOnInit(): void {
        this.case = this.route.parent.snapshot.data.case;
        this.notificationToConfirm = null;

        this.notifications = this.route.snapshot.data.notifications;
        this.documents = this.route.parent.snapshot.data.documents as DocumentModel[];

        if (this.notifications.filter(x => !x.isRead).length === 0) {
            this.showAll = true;
        }

        this.subscriptions.push(this.medicalReportService.approvalCommitted.subscribe((result: MedicalReportRequest) => {
            this.replaceDocument(result.document);
        }));

        this.subscriptions.push(this.medicalReportService.rejectionCommitted.subscribe((result: MedicalReportRequest) => {
            this.replaceDocument(result.document);
        }));

        this.alertWindow.hide();
    }

    deleteNotification(notification: NotificationModel) {

        this.appInsights.logComplexEvent('Delete notification pressed', {
            'notification': notification.id
        });

        this.notificationToConfirm = notification;
        this.alertWindow.open();
    }

    confirmDeleteClosed(positive: boolean): void {

        this.appInsights.logComplexEvent('Confirm notification delete', {
            'notification': this.notificationToConfirm.id,
            'deleteConfirmation': positive ? 'TRUE' : 'FALSE'
        });

        if (!positive) {
            this.notificationToConfirm = null;
            return;
        }

        this.notificationService.deleteNotification(this.notificationToConfirm);
        this.notifications = this.notifications.filter(n => n !== this.notificationToConfirm);
        this.notificationToConfirm = null;
    }

    approveDocument(notification: NotificationDocumentEvent) {
        this.medicalReportService.startApproval(notification.document, this.case);
    }

    rejectDocument(notification: NotificationDocumentEvent) {
        this.medicalReportService.startRejection(notification.document, this.case);
    }

    openTrustPilot(notification: NotificationModel) {
        this.appInsights.logComplexEvent('Trustpilot review started', {
            'notification': notification.id
        });
        window.open('https://uk.trustpilot.com/evaluate/www.minsterlaw.co.uk', '_blank');
    }

    markAsRead(notification: NotificationModel) {

        this.appInsights.logComplexEvent('Notification read', {
            'notification': notification.id
        });

        this.notificationService.markAsRead(notification);
    }

    openDocument(event: NotificationDocumentEvent) {

        this.appInsights.logComplexEvent('Open notification document', {
            'document': event.document.id,
            'notification': event.notification.id
        });

        this.downloadingDocs.Add(event.document.id, true);

        this.documentService.content(event.document).subscribe(
            result => {
                const bytes = AppUtilities.toUTF8Array(result.data);
                const content = new Blob([bytes], { type: mime.lookup(event.document.fileExtension) || 'application/pdf' });
                const filename = `${event.document.name}.${event.document.fileExtension}`;
                AppUtilities.saveToFile(content, filename);
                this.downloadingDocs.Remove(event.document.id);
            }, error => {
                this.downloadingDocs.Remove(event.document.id);
            }
        );
    }
}

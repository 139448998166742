import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CaseModel } from '../../models/case.model';
import { DocumentModel } from '../../models/document.model';
import { KeyedCollection } from '../../data/keyedcollection';
import { Subscription } from 'rxjs';
import { DocumentService } from '../../services/document.service';
import { AppInsightsService } from '../../services/appinsights.service';
import { AppUtilities } from '../../app.utility';
import types from '../../data/loss-types.json';
import mime from 'mime-types';
import { LossLegacyService } from 'src/app/services/loss-legacy.service';

const PAGE_SIZE = 8;

@Component({
  selector: 'app-losses-legacy',
  templateUrl: './losses-legacy.component.html',
  styleUrls: ['./losses-legacy.component.scss']
})
export class LossesLegacyComponent implements OnInit, OnDestroy {

    case: CaseModel;
    documents: DocumentModel[];
    lossDocuments: DocumentModel[];
    groupedLosses: DocumentModel[][];
    subscriptions: Subscription[] = [];
    isSettled: boolean;
    downloadingDocs = new KeyedCollection<boolean>();
    filters: {
        name: string;
        icon: string;
    }[];
    selectedFilter: { name: string; icon: string; };
    sortOrder = 'newest';
    page = 1;
    pageSize = PAGE_SIZE;
    olderLossesPage = 1;

    constructor(
        private route: ActivatedRoute,
        private documentService: DocumentService,
        private lossService: LossLegacyService,
        private appInsights: AppInsightsService) {

        this.filters = [];
        this.filters.push({ name: 'All', icon: 'list' });
        for (let i = 0; i < types.lossTypes.length; i++) {
            this.filters.push(types.lossTypes[i]);
        }
        this.selectedFilter = this.filters[0];
    }

    filterBy(filter: { name: string, icon: string; }) {
        this.selectedFilter = filter;
        this.groupedLosses = this.groupLosses();
        this.page = 1;
    }

    sortBy(value: string) {
        this.sortOrder = value;
        this.groupedLosses = this.groupLosses();
        this.page = 1;
    }

    get pagedLosses(): DocumentModel[][] {
        return this.groupedLosses.slice((this.page - 1) * PAGE_SIZE, this.page * PAGE_SIZE);
    }

    private groupLosses(): DocumentModel[][] {

        const items = new KeyedCollection<DocumentModel[]>();

        this.lossDocuments = this.documents.filter(d => d.group.toLowerCase() === "losses");

        this.lossDocuments.forEach(d => {

            if (!d.groupId) {
                return;
            }

            if (this.selectedFilter.name !== this.filters[0].name) {
                // Filter on the type.
                if (d.lossType && d.lossType.toLowerCase() !== this.selectedFilter.name.toLowerCase()) {
                    return;
                }
            }

            const groupId = d.groupId || new Date(d.modifiedDate).toISOString();

            const arr: DocumentModel[] = items.Item(groupId) || [];
            arr.push(d);

            items.Add(groupId, arr);
        })

        const returnVal = items.Values();

        return returnVal.sort((a, b) => {
            if (this.sortOrder === 'newest') {
                return new Date(a[0].lossIncurred) > new Date(b[0].lossIncurred) ? -1 : 1;
            } else {
                return new Date(a[0].lossIncurred) < new Date(b[0].lossIncurred) ? -1 : 1;
            }
        });
    }

    shortenName(doc: DocumentModel) {
        if (doc.name.length > 10) {
            return doc.name.substr(0, 10) + '...';
        }
        return doc.name;
    }

    get legacyLosses(): DocumentModel[] {
        return (this.lossDocuments || []).filter(d => !d.groupId);
    }

    get pagedLegacyLosses(): DocumentModel[] {
        return this.legacyLosses.slice((this.olderLossesPage - 1) * PAGE_SIZE, this.olderLossesPage * PAGE_SIZE);
    }

    isDownloadingDoc(doc: DocumentModel) {
        return this.downloadingDocs.ContainsKey(doc.id)
    }

    download(loss: DocumentModel) {
        this.appInsights.logComplexEvent('downloadLoss', { loss: loss.id });

        this.downloadingDocs.Add(loss.id, true);

        this.documentService.content(loss).subscribe(
            result => {
                const bytes = AppUtilities.toUTF8Array(result.data);

                const content = new Blob([bytes], { type: mime.lookup(loss.fileExtension) || 'application/pdf' });
                const filename = `${loss.name}.${loss.fileExtension}`;
                AppUtilities.saveToFile(content, filename);
                this.downloadingDocs.Remove(loss.id);
            }, error => {
                this.downloadingDocs.Remove(loss.id);
            }
        );
    }

    ngOnInit(): void {

        this.case = this.route.parent.parent.snapshot.data.case;
        this.documents = this.route.parent.parent.snapshot.data.documents;
        this.isSettled = this.case.endOfClaim;

        this.groupedLosses = this.groupLosses();

        this.subscriptions.push(this.lossService.addLossCommitted.subscribe(() => {
            this.documentService.get(this.case.reference).subscribe(docs => {
                this.documents = docs;
                this.groupedLosses = this.groupLosses();
            });
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    addLoss(): void {
        this.lossService.startAddLoss(this.case);
    }
}
